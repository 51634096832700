@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
:root {
  --clr-background: rgb(247, 244, 244);
  --clr-gray: rgb(119, 114, 114);
  --font-main: "Montserrat", sans-serif;
  --clr-dark-gray: #262626;
  --clr-dark-black: #333;
  --clr-dark-text: rgb(226, 225, 225);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Hind Madurai", sans-serif;
  margin: 0 auto;
  overflow-x: hidden;
}

.wrapper {
  background-color: var(--clr-background);
}

/* -------------------------Header/Navbar */
header {
  font-family: var(--font-main);
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
}

.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}

.navbar__ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  width: 100%;
  max-width: 500px;
}

.navbar__ul .name {
  font-size: 23px;
  font-weight: 600;
}

.navbar__ul a {
  text-decoration: none;
  color: black;
}

.navbar__ul li {
  background: rgba(235, 230, 230, 0.3);
  width: 50%;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.link,
.portfolio__header,
footer h2 {
  text-transform: uppercase;
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-bottom: 2rem;
  margin: 0 auto;
  max-width: 80%;
}

.header__container img {
  width: 70%;
}

.header__text {
  width: 100%;
  margin-top: 20px;
  line-height: 1.5rem;
  text-align: center;
}
/* End of Header/Navbar--------------------- */

/* Portfolio section ----------------------- */
.portfolio,
.portfolio__container {
  display: flex;
}

.portfolio {
  background-color: rgb(238, 237, 237);
  margin-top: 2.5rem;
  padding-top: 3rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.portfolio__header {
  font-family: var(--font-main);
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.portfolio__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  place-items: center;
  grid-gap: 0.5rem;
  text-align: center;
  align-items: stretch;
  width: 100%;
  padding: 0.5rem;
}

.card {
  margin: 1rem 0;
  border: 1.5px solid #888;
  padding: 0.5rem;
  gap: 1rem;
  width: 350px;
  max-width: 100%;
  height: 100%;
}

.card img {
  width: 25%;
  margin-bottom: 1rem;
}

.card h2 {
  font-family: var(--font-main);
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.text-container {
  width: 70%;
  margin: 0 auto;
}

/* End of services------------------------ */

/* Skills --------------------------------- */
.skills {
  text-align: center;
  margin-top: 2rem;
}

.skills h2 {
  font-family: var(--font-main);
  text-transform: uppercase;
  font-size: 2rem;
}

.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  grid-gap: 1rem;
  margin-top: 2rem;
  text-align: center;
}

.skills__item {
  margin-bottom: 1rem;
  cursor: pointer;
}

.skills__item img:hover {
  transform: scale(1.1);
  transition: ease-out 0.3s;
}

.skills img {
  max-width: 80px;
  margin-bottom: -5px;
}

/* Footer --------------------------------- */

footer {
  font-family: var(--font-main);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background-color: var(--clr-background);
}

.footer__nav {
  width: 100%;
  display: flex;
  min-height: 30px;
  padding: 10px;
  bottom: 0;
  text-align: center;
  justify-content: space-between;
}


.footer__text-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.footer__text-container p {
  margin-bottom: 0;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}


footer h2 {
  font-size: 2rem;
  margin-top: 2rem;
}

.footer__nav-links {
  display: none;
}

.footer__nav-links li a {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
}

.footer__nav-hr {
  width: 100%;
}

footer p {
  max-width: 400px;
  padding-bottom: 1rem;
  margin: 0 auto;
}

.to-top {
  list-style-type: none;
  margin-right: 10px;
  justify-self: flex-end;
}

.to-top a {
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 768px) {
  .header__container {
    min-width: 600px;
  }

  header {
    padding-bottom: 4rem;
  }

  .navbar {
    width: 100%;
    display: flex;
    bottom: 0;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .navbar ul {
    display: flex;
    flex-direction: row;
  }

  .navbar__ul li {
    padding: 5px 15px 15px 10px;
    background-color: transparent;
  }

  .header__container {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  .header__container img {
    max-width: 300px;
  }

  .header__container p {
    max-width: 350px;
    text-align: left;
  }

  /* Portfolio section at 768----------- */
  .portfolio__container {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1165px;
  }

  /* Skills at 768 ------------------- */

  .skills-container {
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  .skills h2 {
    margin-bottom: -10px;
    text-transform: uppercase;
    font-size: 2rem;
  }

  .skills__item {
    margin-left: 20px;
    margin-right: 20px;
  }

  /* Footer at 768px ---------------- */
  footer {
    display: flex;
    align-items: center;
  }

  .phone,
  .email {
    margin-right: 8px;
  }

  .footer__nav {
    width: 95%;
  }

  .footer__nav h2 {
    text-transform: none;
  }

  .footer__nav-links {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin-left: auto;
  }

  .footer__nav-links li {
    padding: 5px 10px 10px 10px;
    text-decoration: none;
  }

  .footer__nav-hr {
    width: 95%;
    border-color: rgb(255, 255, 255);
  }

  .to-top {
    display: none;
  }
}

/* min width 1024 ---------------------------------- */
@media screen and (min-width: 1024px) {
  .navbar,
  .navbar__ul,
  .header__container {
    display: flex;
  }

  .navbar {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar__ul {
    flex-direction: row;
  }

  .navbar__ul li {
    background-color: transparent;
  }

  .navbar__ul .link {
    margin: 0;
  }

  .header__container {
    flex-direction: row-reverse;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  .header__container img {
    max-width: 330px;
  }

  .header__text {
    max-width: 400px;
    text-align: center;
  }

  /* Portfolio section------------------------- */
  .portfolio__container {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  /* End Portfolo------------------------------ */
}

/* Dark mode------------------------------- */
button[class="darkmode"] {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgb(43, 43, 43);
  border-radius: 50%;
}

.darkmode .portfolio {
  background-color: var(--clr-dark-black);
  color: var(--clr-dark-text);
}

.darkmode footer {
  background-color: var(--clr-dark-black);
  color: var(--clr-dark-text);
}

.darkmode .wrapper {
  background-color: var(--clr-dark-black);
  color: var(--clr-dark-text);
}

.darkmode .navbar__ul li {
  color: var(--clr-dark-text);
  background-color: transparent;
}

.darkmode a[href] {
  color: var(--clr-dark-text);
}
.darkmode .contact-section {
  background-color: var(--clr-dark-black);
  box-shadow: none;
}

.darkmode .contact-form {
  background-color: var(--clr-dark-black);
  color: var(--clr-dark-text);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.darkmode .contact-form input,
.darkmode .contact-form textarea {
  background-color: var(--clr-dark-gray);
  color: var(--clr-dark-text);
  border: 1px solid var(--clr-dark-text);
}

.darkmode .contact-form label {
  color: var(--clr-dark-text);
}

.darkmode .footer__text-container {
  background-color: var(--clr-dark-black);
  color: var(--clr-dark-text);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.darkmode .footer__text-container h2 {
  color: var(--clr-dark-text);
}

.darkmode .footer__text-container p {
  color: var(--clr-dark-text);
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.field {
  margin-bottom: 15px;
}

.field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.field input[type="text"],
.field input[type="email"],
.field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field textarea {
  resize: vertical;
  min-height: 100px;
}

#button {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

#button:hover {
  background-color: #0056b3;
}
.footer__text-container {
  margin: 40px auto;
  padding: 20px;
  max-width: 600px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.footer__text-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.footer__text-container p {
  margin-bottom: 0;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.contact-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-section .footer__text-container,
.contact-section .contact-form {
  flex: 1;
  margin: 20px;
}

.contact-form {
  background-color: #f3f3f382;
  padding: 20px;
  margin: 20px 20px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.field {
  margin-bottom: 15px;
}

.field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.field input[type="text"],
.field input[type="email"],
.field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field textarea {
  resize: vertical;
  min-height: 100px;
}

#button {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

#button:hover {
  background-color: #0056b3;
}